<template>
  <BaseCard
    v-if="content"
    class="flex flex-col justify-between shadow-lg"
  >
    <header>
      <h2 class="text-3xl font-bold">
        {{ content.fullName ? content.fullName : content.name }}
      </h2>
      <ul class="flex text-gray-600">
        <li
          v-if="content.minutes"
          class="mr-4"
        >
          <span class="text-2xl text-gray-800 font-medium">{{ content.minutes }}</span> minutes
        </li>
        <li
          v-if="content.fullName === 'Personality'"
          class="mr-4"
        >
          <span class="text-2xl text-gray-800 font-medium">30</span> minutes
        </li>
        <li v-if="content.questionsCount" >
          <span
            class="text-2xl text-gray-800 ml-2 font-medium"
          >{{ content.questionsCount }}</span> questions
        </li>
      </ul>

      <p
        v-if="content.description"
        class="text-lg mt-6"
      >
        {{ content.description }}
      </p>
    </header>

    <div>
      <p
        v-if="!examHasBeenAttempted(content.slug) && !canStartExam(content.slug)"
        class="mt-6 text-gray-600"
      >
        You’ve already started. Contact your test provider to activate a new test.
      </p>
      <p
        v-else
        class="mt-6 text-gray-600"
      >
        Please read the instructions carefully before starting the assessment.
      </p>

      <div
        v-if="examHasBeenAttempted(content.slug)"
        class="mt-6"
      >
        <BaseButton
          variant="gray"
          class="w-full text-gray-800"
          disabled
        >
          <template slot="iconLeft">
            <Icon
              width="20px"
              height="20px"
              view-box="0 0 24 24"
              class="mr-2"
            >
              <Check />
            </Icon>
          </template>
          Completed
        </BaseButton>
      </div>

      <div
        v-else-if="!examHasBeenAttempted(content.slug) && !canStartExam(content.slug)"
        class="mt-6"
      >
        <BaseButton
          variant="gray"
          class="w-full text-gray-800"
          disabled
        >
          <template slot="iconLeft">
            <Icon
              width="20px"
              height="20px"
              view-box="0 0 24 24"
              class="mr-2"
            >
              <Edit />
            </Icon>
          </template>
          Incomplete
        </BaseButton>
      </div>

      <div
        v-else-if="!examHasBeenAttempted(content.slug) && canStartExam(content.slug)"
        class="mt-6 flex -mx-2"
      >
        <div class="w-full px-2">
          <BaseButton
            :href="`assessments/${content.slug}?${candidateQuery}`"
            class="w-full text-center"
            :variant="customButtonColour ? 'custom' : 'green'"
            :custom-background-colour="customButtonColour"
          >
            <template slot="iconLeft">
              <Icon
                width="20px"
                height="20px"
                view-box="0 0 24 24"
                class="mr-2"
              >
                <Edit />
              </Icon>
            </template>
            Take test
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Edit from '@components/Icons/Edit'
import Check from '@components/Icons/Check'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Edit,
    Check
  },

  props: {
    content: {
      type: Object,
      default: null
    },
    query: {
      type: Object
    },
    customButtonColour: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      attempts: 'candidates/attempts',
      canStartExam: 'candidates/canStartExam'
    }),

    candidateQuery() {
      // Remove undefined params from query object
      // eslint-disable-next-line
      const cleanedQuery = Object.keys(this.query).forEach(key => this.query[key] === undefined && delete this.query[key])

      // Create query string
      return Object.keys(this.query).map(key => key + '=' + this.query[key]).join('&')
    }
  },

  methods: {
    examHasBeenAttempted(examSlug) {
      return this.attempts.find(attempt => attempt.examSlug === examSlug)
    }
  }
}
</script>
